.wd-pay-success[data-v-7c05bb22] {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
@media screen and (max-width: 780px) {
.wd-pay-success .icon .iconfont[data-v-7c05bb22] {
    font-size: 0.38rem;
    color: #4CD263;
}
.wd-pay-success .icon p[data-v-7c05bb22] {
    font-size: 0.24rem;
}
}
@media screen and (min-width: 780px) {
.wd-pay-success .icon[data-v-7c05bb22] {
    display: flex;
    align-items: center;
}
.wd-pay-success .icon .iconfont[data-v-7c05bb22] {
    font-size: 0.55rem;
    color: #4CD263;
}
.wd-pay-success .icon p[data-v-7c05bb22] {
    font-size: 0.24rem;
    margin-left: 0.2rem;
}
}
.wd-pay-success .button[data-v-7c05bb22] {
  margin-top: 0.8rem;
  width: 2rem;
  height: 0.3rem;
  line-height: 0.3rem;
  background: #4CD263;
  color: #fff;
  border-radius: 0.3rem;
}
.wd-pay-success .button[data-v-7c05bb22]:hover {
  background: #23C343;
}
